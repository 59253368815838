import { FunctionComponent } from "react";

const Homepage: FunctionComponent = () => {
  return (
    <div className="relative bg-gray w-full h-[1024px] overflow-hidden flex flex-col items-center justify-center py-[150px] px-[495px] box-border gap-[18px] text-center text-[64px] text-white font-inria-sans">
      <img
        className="relative w-[340px] h-[340px] object-cover"
        alt=""
        src="/logo@2x.png"
      />
      <div className="relative inline-block w-[449px] h-[76px] shrink-0">
        Coming Soon
      </div>
      <div className="relative inline-block w-[449px] h-[76px] shrink-0 text-[16px]">
        <span>CandleTech 2024</span>
        <span className="text-[10px]">©</span>
      </div>
    </div>
  );
};

export default Homepage;
